import "regenerator-runtime";
import * as React from "react";
import { createRoot } from "react-dom/client";
import { LoadingScreen } from "./bootstrap/loading";

async function main() {
    const timeStart = Date.now();

    const appContainer = document.createElement("div");
    const root = createRoot(appContainer);
    root.render(React.createElement(LoadingScreen));
    document.body.append(appContainer);
    

    /* boot up app */
    const bootstrapper = await import("./bootstrap/initialize");
    await bootstrapper.initializeApp();
    await bootstrapper.renderApp(root);

    const timeEnd = Date.now();
    console.log("App started within %dms", timeEnd - timeStart);
}

main().then(undefined);
