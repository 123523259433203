import React from "react";
import cssStyle from "./loading.module.scss";
import { CircularProgress } from "@mui/material";

export const LoadingScreen = React.memo(() => {
    /*
     * Just show a minimalistic loading spinner.
     * It might only flash for a few hundreds of a second.
     */
    return (
        <div className={cssStyle.container}>
            <div className={cssStyle.inner}>
                <CircularProgress />
            </div>
        </div>
    );
});
