// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zFKbPunHr8VKbWEKznNq{position:fixed;inset:0;display:flex;flex-direction:column;justify-content:center;background:#ededed}.K60OUDZ1Hf3R1SvoxlEw{align-self:center}`, "",{"version":3,"sources":["webpack://./src/bootstrap/loading.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,OAAA,CAEA,YAAA,CACA,qBAAA,CACA,sBAAA,CAEA,kBAAA,CAGF,sBACE,iBAAA","sourcesContent":[".container {\n  position: fixed;\n  inset: 0;\n\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n\n  background: #ededed;\n}\n\n.inner {\n  align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `zFKbPunHr8VKbWEKznNq`,
	"inner": `K60OUDZ1Hf3R1SvoxlEw`
};
export default ___CSS_LOADER_EXPORT___;
